import React from 'react';

const MapView = () => {
    return (
        <div style={{ width: '100%', height: '100vh' }}>
            <iframe
                src={process.env.PUBLIC_URL + '/leaflet/leaflet.html'}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Map"
            ></iframe>
        </div>
    );
};

export default MapView;
