import React from 'react';
import './Profile.css';
import { FaArrowRight, FaUser, FaCrown, FaLanguage, FaLandmark, FaSignOutAlt } from 'react-icons/fa';

const Profile = () => {
  return (
    <div className="profile-page">
      <div className="profile-header">
        <img src="https://via.placeholder.com/120" alt="Profile" className="profile-pic" />
        <h2>Kartik M</h2>
      </div>
      
      <div className="profile-section">
        <div className="section-header">
          <FaUser className="section-icon" />
          <h3>Contact Details</h3>
          <FaArrowRight className="section-arrow" />
        </div>
      </div>
      
      <div className="profile-section">
        <div className="section-header">
          <FaCrown className="section-icon" />
          <h3>My Subscriptions</h3>
          <FaArrowRight className="section-arrow" />
        </div>
      </div>
      
      <div className="profile-section">
        <div className="section-header">
          <FaLanguage className="section-icon" />
          <h3>Language Settings</h3>
          <FaArrowRight className="section-arrow" />
        </div>
      </div>

      <div className="profile-section">
        <div className="section-header">
          <FaLandmark className="section-icon" />
          <h3>My Info</h3>
          <FaArrowRight className="section-arrow" />
        </div>
      </div>
      
      <div className="profile-section">
        <div className="section-header">
          <FaSignOutAlt className="section-icon" />
          <h3>Logout</h3>
          <FaArrowRight className="section-arrow" />
        </div>
      </div>
    </div>
  );
};

export default Profile;
