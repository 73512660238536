import React, { useState } from 'react';
import { FaHome, FaEnvelope, FaSignInAlt, FaPlus, FaHeart, FaSearch, FaUser } from 'react-icons/fa';
import SignInModal from './SignInModal'; // Import the SignInModal component
import ContactModal from './ContactModal'; // Import the ContactModal component
import './Header.css';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
    setShowSignInModal(false);
  };

  return (
    <header className="header">
      <div className="logo">LandLayout</div>
      <nav className="nav">
        <a href="#home">Home</a>
        <a href="#layout">Layout List</a>
        <a href="#contact">Contact Us</a>
        {isLoggedIn ? (
          <a href="/profile" className="profile-link">
            <FaUser />
            <span>Profile</span>
          </a>
        ) : (
          <a href="#login" onClick={() => setShowSignInModal(true)}>Login</a>
        )}
      </nav>

      <div className="bottom-nav">
        <a href="#home" className="bottom-nav-item">
          <FaHome />
          <span>Home</span>
        </a>
        <a href="#wishlist" className="bottom-nav-item">
          <FaHeart />
          <span>Wishlists</span>
        </a>

        <button className="plus-button" onClick={() => setShowContactModal(true)}>
          <FaPlus />
        </button>

        <a href="#shortlist" className="bottom-nav-item">
          <FaSearch />
          <span>Explore</span>
        </a>
        {isLoggedIn ? (
          <a href="/profile" className="bottom-nav-item">
            <FaUser />
            <span>Profile</span>
          </a>
        ) : (
          <a href="#login" className="bottom-nav-item" onClick={() => setShowSignInModal(true)}>
            <FaSignInAlt />
            <span>Login</span>
          </a>
        )}
      </div>

      {/* Sign-In Modal */}
      {showSignInModal && <SignInModal onClose={() => setShowSignInModal(false)} onLogin={handleLogin} />}

      {/* Contact Modal */}
      {showContactModal && <ContactModal onClose={() => setShowContactModal(false)} />}
    </header>
  );
};

export default Header;
