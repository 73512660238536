import React, { useState } from 'react';
import './ContactModal.css'; // Create this CSS file for styling
import { FaTimes } from 'react-icons/fa'; // Import Font Awesome icon

const ContactModal = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement contact form submission logic here
    alert('Contact form submitted');
    onClose(); // Close the modal after submission
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <FaTimes className="close-icon" onClick={onClose} />
        <h2 className="heading">Want To List Your Layout?</h2>
        <h2 className="heading">Request A Callback</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="Name"
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </div>
          <div className="form-group">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder="Your Message"
              rows="4"
            />
          </div>
          <button type="submit" className="submit-button">Send</button>
        </form>
      </div>
    </div>
  );
};

export default ContactModal;
